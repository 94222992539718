
        <template>
  <v-layout class="justify-center" style="padding-bottom:60px;">
      <v-flex xl10 lg10 md11 sm11 xs11>
          <v-row class="mx-3" style="padding-top:60px;">
        <v-col
          v-for="card in BusinesUnitMembers" :key="card.id"
         class="d-flex child-flex"
          >
          <v-card width="350" height="250"
                light :to="'/BusinessMember/'+card.id"
                :style="{ backgroundImage: 'linear-gradient(60deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0.5) 100%),url(' + require('@/assets/logo.png') + ')'  ,backgroundSize: 'cover'}">
            
                <v-list dense 
                class="transparent">
              <v-list-item>
                <v-list-item-avatar tile size="80">
                  <v-img contain src="@/assets/logo.png"></v-img>
                </v-list-item-avatar><v-spacer></v-spacer>
                <v-list-item-avatar
                v-if="card.Profile_Photo"
                  size="80"
                  color="grey"
                >
                <img 
                style="object-fit: cover;"
                :src="card.Profile_Photo">                
                </v-list-item-avatar>
                <v-list-item-avatar
                v-if="!card.Profile_Photo && AppisDarkMode"
                  size="80"
                  color="grey"
                >               
                <img 
                src="@/assets/ImageHolder_dark.png">
                </v-list-item-avatar>
                <v-list-item-avatar
                  size="120"
                  color="grey"
                  v-if="!card.Profile_Photo && !AppisDarkMode"
                >
                <img 
                src="@/assets/ImageHolder.png">
                </v-list-item-avatar>
              </v-list-item>
              <hr class="accent" style="width:90%;">
              <div class="caption" style="padding-left:20px;" v-html="card.LocationSlogan">
              </div>    
              <hr class="accent" style="width:80%;">
              <v-list-item>
                  <v-list-item-content >
                      <v-list-item-title class="overline" style="font-weight:bold;">{{card.Full_Name}}</v-list-item-title>      
                      <v-list-item-subtitle style="font-weight:bold;">
                      {{card.Position}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  <v-list-item-content >
                      <v-list-item-title>{{card.Business_UnitName}}</v-list-item-title> 
                       <v-list-item-subtitle style="font-weight:bold;">
                        {{card.Mobile_Number}}
                      </v-list-item-subtitle> 
                       <v-list-item-subtitle v-if="card.Work_Number" style="font-weight:bold;">
                        {{card.Work_Number}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="!card.Work_Number && card.LocationWork_Number" style="font-weight:bold;">
                        {{card.LocationWork_Number}}
                      </v-list-item-subtitle> 
                       <!-- <v-list-item-subtitle style="font-weight:bold;">
                        {{card.Mobile_Number}}
                      </v-list-item-subtitle>                -->              
                    </v-list-item-content>
              </v-list-item>
            </v-list>
           

            </v-card>
        </v-col>
      </v-row>
      </v-flex>
  </v-layout>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    data(){
        return {
            BusinesUnitMembers: []
        }
    },
    computed:{

    },
    created(){
        this.GetBusinesUnitMembers()
    },
    methods:{
        GetBusinesUnitMembers(){
            db.collection('businessmembers').onSnapshot(res => {
                const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.BusinesUnitMembers.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                       
                        }  
                    }) 
            })
        }
    }
}
</script>

<style>

</style>

    